var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "survey-card" }, [
    _c("div", { staticClass: "survey-card__main" }, [
      _c(
        "div",
        {
          staticClass: "survey-card__main_name",
          style: { display: "flex", "margin-bottom": "7px" },
          on: { click: _vm.data.click },
        },
        [
          _c("div", {
            staticClass: "fw500 flex bet a-center",
            style: { display: "flex", "margin-bottom": "7px" },
            domProps: { textContent: _vm._s(_vm.survey.title) },
          }),
        ]
      ),
      _c("div", { staticClass: "survey-card__main_main" }, [
        _c(
          "div",
          {
            staticClass: "survey-card__main_name",
            style: { display: "grid", "justify-content": "center" },
            on: { click: _vm.data.click },
          },
          [
            _c("div", {
              staticClass: "grey mie4",
              domProps: { textContent: _vm._s(_vm.$t("Created at")) },
            }),
            _c("div", [
              _c("span", {
                staticClass: "opened",
                domProps: {
                  textContent: _vm._s(
                    _vm.filters.date(_vm.survey.created_at, "DD/MM/YYYY")
                  ),
                },
              }),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "survey-card__main_name flex",
            on: { click: _vm.data.click },
          },
          [
            _c("div", {
              staticClass: "grey mie4",
              domProps: { textContent: _vm._s(_vm.$t("_Opened")) },
            }),
            _c("div", [
              _c("span", {
                staticClass: "opened",
                domProps: { textContent: _vm._s(_vm.survey.opened) },
              }),
              _c("span", {
                staticClass: "sent",
                domProps: { textContent: _vm._s("/" + _vm.survey.sent) },
              }),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "survey-card__main_name flex",
            on: { click: _vm.data.click },
          },
          [
            _c("div", {
              staticClass: "grey mie4",
              domProps: { textContent: _vm._s(_vm.$t("Answered")) },
            }),
            _c("div", {
              domProps: { textContent: _vm._s(_vm.survey.answered) },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "survey-card__main_name flex",
            on: { click: _vm.data.click },
          },
          [
            _c("div", {
              staticClass: "grey mie4",
              domProps: { textContent: _vm._s(_vm.$t("Score")) },
            }),
            _c("div", {
              domProps: {
                textContent: _vm._s(
                  Math.round(_vm.survey.total_score * 100) / 100
                ),
              },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "survey-card__main_name flex",
            on: { click: _vm.data.click },
          },
          [
            _c("div", {
              staticClass: "grey mie4",
              domProps: { textContent: _vm._s(_vm.$t("Unreads")) },
            }),
            _c("div", { domProps: { textContent: _vm._s(_vm.survey.badge) } }),
          ]
        ),
        _c(
          "div",
          { staticClass: "survey-card__main_name flex" },
          [
            _c("swich-button", {
              attrs: { value: _vm.survey.active },
              on: { input: _vm.data.setActive },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }